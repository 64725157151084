import React from "react"
import logo from "./logo.svg"
import "./App.css"

function App() {
  return (
    <div className="App">
      <a href="mailto:info@hya.io" className="Info">
        <img src={logo} alt="hya.io" />
        <span>HYA.IO LTD</span>
      </a>
    </div>
  )
}

export default App
